import React, { useState } from "react";
import { graphql } from "gatsby";
import PageHeader from "../components/_global/layout/PageHeader";
import Section from "../components/_global/layout/Section";
import Block from "../components/_global/editorial/Block";
import Layout from "../components/_global/layout/Layout";
import localize from "../components/_global/localization/localize";
import Spacer from "../components/_global/layout/Spacer";
import ProjectList from "../components/8_research/ProjectList";
import TagFilters from "../components/8_research/TagFilters";

const Research = ({ data, ...props }) => {
	const { page, projects: { nodes: projects } } = data;
	const [time, setTime] = useState("all");

	const filteredProjects = [];
	for (let i = 0; i < projects.length; i++) {
		// if the category does not exists
		if (!filteredProjects.find(item => item.time === projects[i].time)) {
			// create the year array
			filteredProjects.push({
				time: projects[i].time,
				items: []
			});
			// push to the time array
			filteredProjects.find(item => item.time === projects[i].time).items.push(projects[i]);
		} else {
			// push to the time array
			filteredProjects.find(item => item.time === projects[i].time).items.push(projects[i]);
		}
	}
	const currentData = time === "all" ? projects : filteredProjects.find(i => i.time === time).items;

	return (
		<Layout title={page.common.title}>
			<PageHeader title={page.common.title} />

			<Section m="small">
				<Block data={page.text} />
				<Spacer s="large" />

				<TagFilters
					data={filteredProjects}
					tags={{
						all: page.allProjects,
						past: page.pastTagPlural,
						present: page.presentTagPlural,
						future: page.futureTagPlural
					}}
					time={time}
					setTime={setTime}
				/>
				<Spacer s="medium" />

				<ProjectList data={currentData} past={page.pastTag} present={page.presentTag}
										 future={page.futureTag} />
			</Section>
		</Layout>
	);
};

export default localize(Research);

export const query = graphql`
    {
        page: sanityResearch {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            allProjects {
                _type
                fr
            }
            pastTagPlural {
                _type
                fr
            }
            presentTagPlural {
                _type
                fr
            }
            futureTagPlural {
                _type
                fr
            }
            pastTag {
                _type
                fr
            }
            presentTag {
                _type
                fr
            }
            futureTag {
                _type
                fr
            }
            text {
                ...CompleteBlockFragment
            }
        }
        projects: allSanityProject {
            nodes {
                title {
                    _type
                    fr
                }
                slug {
                    current
                }
                time
                image {
                    ...ImageFragment
                }
                content {
                    ...CompleteBlockFragment
                }
            }
        }
    }
`;