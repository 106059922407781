import React from "react";
import styled from "styled-components";
import Tag from "../_global/Tag";
import Spacer from "../_global/layout/Spacer";

const Comp = styled.div`
  display: flex;
  flex-wrap: wrap;

  p {
    margin-bottom: 1rem;
  }
`;

const TagFilters = (props) => {
	const { data, tags, setTime, time } = props;

	return (
		<Comp>
			<Tag clickable onClick={() => setTime("all")} selected={time === "all"}>
				{tags.all}
			</Tag>
			<Spacer s="small" />

			{data.find(i => i.time === "past") ?
				<>
					<Tag clickable onClick={() => setTime("past")} selected={time === "past"}>
						{tags.past}
					</Tag>
					<Spacer s="small" />
				</> : null}

			{data.find(i => i.time === "present") ?
				<>
					<Tag clickable onClick={() => setTime("present")} selected={time === "present"}>
						{tags.present}
					</Tag>
					<Spacer s="small" />
				</> : null}

			{data.find(i => i.time === "future") ?
				<>
					<Tag clickable onClick={() => setTime("future")} selected={time === "future"}>
						{tags.future}
					</Tag>
				</> : null}
		</Comp>
	);
};

export default TagFilters;