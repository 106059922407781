import React from "react";
import styled from "styled-components";
import Text from "../_global/editorial/Text";
import Tag from "../_global/Tag";
import Block from "../_global/editorial/Block";
import Spacer from "../_global/layout/Spacer";
import AltImage from "../_global/AltImage";
import Conditional from "../_global/layout/Conditional";

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    flex-direction: column;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const TextImage = styled.div`
  display: flex;
`;

const Content = styled.div`
  flex: 1;
`;

const Image = styled(AltImage)`
  margin-top: 0.5rem;
  width: 26.875rem;
  height: 15.9375rem;
  object-fit: cover;
  object-position: center center;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    flex-direction: column-reverse;
    width: 100%;
    height: 255px;
  }
`;

const HiddenAnchor = styled.div`
  //display: block;
  position: relative;
  top: -150px;
  visibility: hidden;

  @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
    top: -100px;
  }
`;

const Project = (props) => {
	const { data, tags } = props;
	return (
		<Comp>
			<HiddenAnchor id={data.slug.current} />
			<Header>
				<Text s="medium" w="bold">
					{data.title}
				</Text>
				<Spacer s="medium" />

				<Tag>
					{tags[data.time]}
				</Tag>
			</Header>

			<Conditional desktop>
				<Spacer s="medium" />
			</Conditional>
			<Conditional mobile>
				<Spacer s="small" />
			</Conditional>

			<TextImage>
				<Content>
					<Conditional mobile>
						<Image data={data.image} />
						<Spacer s="medium" />
					</Conditional>

					<Block data={data.content} />

					<Conditional desktop>
						<Spacer s="large" />
					</Conditional>
					<Conditional mobile>
						<Spacer s="medium" />
					</Conditional>
				</Content>

				<Conditional desktop>
					<Spacer s="medium" />
					<Image data={data.image} />
				</Conditional>
			</TextImage>

			<Conditional desktop>
				<Spacer s="medium" />
			</Conditional>

			<Conditional mobile>
				<Spacer s="small" />
			</Conditional>
		</Comp>
	);
};

export default Project;
