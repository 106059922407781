import React from "react";
import styled from "styled-components";
import Project from "./Project";

const Comp = styled.div`

`;

const ProjectList = (props) => {
	const { data, past, present, future } = props;
	// console.log(data);

	const projects = data.map((item, index) => {
		return (
			<Project key={index} data={item} tags={{ past, present, future }} />
		);
	});

	return (
		<Comp>
			{projects}
		</Comp>
	);
};

export default ProjectList;
